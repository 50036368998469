.homeWrapper {
  width: 100%;
  height: 100vh;

  @include centeredBox;

  #tsparticles {
    position: absolute;
    width: 100%;
    height: calc(100vh - 0px);
    overflow: hidden;
    z-index: -10;
    background-color: transparent;
    opacity: 0.4;

    canvas {
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }

  img {
    width: 70%;
    max-width: 300px;
    margin-bottom: 25px;
    background-color: $bgPrimary;
  }

  h1 {
    color: $iLabs_blue;
    font-size: 35px;
    font-weight: normal;
    font-family: Roboto;
    margin-bottom: 150px;
  }

  .googleLogin {
    color: $white;
    @include centeredRow;

    background-color: $bgPrimary;
    padding: 15px;
    font-size: 16px;
    font-weight: normal;
    font-family: Roboto;

    border-radius: 8px;
    border: none;
    box-shadow: 2px 2px 5px #000000aa;
    transition-duration: 0.3s;
    cursor: pointer;
    
    &:hover {
      box-shadow: 2px 2px 15px #000000aa;
      // box-shadow: 2px 2px 15px $iLabs_blue;
    }

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 0px;
      margin-right: 15px;
    }
  }
}
