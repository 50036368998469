.ProductSelector {
  @include centeredRow;
  justify-content: flex-start;
  margin-bottom: 25px;
  margin-top: 17px;

  .child {
    // width: 30px;
    max-width: 30px;
    height: 30px;
    margin: 8px 8px;
    border-radius: 40px;
    overflow: hidden;
    cursor: pointer;
    border: solid 1px $bgPrimary;
    transition-duration: 0.5s;
    @include centeredRow;

    &.active{
        border: solid 1px $appPrimary;
        // width: auto;
        max-width: 155px;
    }
    img {
        width: 28px;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
    }

    .name{
        // width: 30px;
        padding: 0px 8px;
        color: $appPrimary;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
    }
  }
}
