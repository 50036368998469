.dashUsers_container {
  .userList {
    height: calc(100vh - 250px);

    margin-top: 25px;
    margin-bottom: 25px;
    overflow-y: scroll;

    @include scrollbars(2px, $bgPrimary);

    .userCard_container {
      background-color: mix(#000000, $bgPrimary, 10);
      border: solid 2px $bgPrimary;
      @include randomFontColors;
      @include centeredRow;
      padding: 5px 8px;
      margin: 4px 0px 8px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        background-color: mix(#000000, $bgPrimary, 5);
      }
      &.active {
        // background-color: mix(#000000, $blue, 50);
        border: solid 2px $blue;
      }

      p {
        flex: 1;
        text-align: left;
        font-size: 14px;
      }

      .email {
        color: $appPrimary;
      }
      .permissions {
        color: $appPrimary;
        text-align: right;
        i{
          margin: 5px
        }
      }
    }
  }

  .userDetails_contsiner {
    .content {
      @include centeredRow;
      .userDetails {
        text-align: left;
        color: $white;
        font-size: 14px;
        line-height: 24px;

        .key {
          color: $blue;
        }
      }

      img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin-right: 50px;
        border-radius: 50%;

        &::before {
          display: block;
          width: 150px;
          height: 150px;
          content: "";
          background-color: aqua;
          box-shadow: inset 0px 0px 15px $bgPrimary;
        }
      }
    }
  }

  .userPermission_contsiner {
    .content {
      @include centeredRow;
      flex-wrap: wrap;
      margin-top: 25px;

      .permissionCard {
        background-color: mix(#000000, $bgPrimary, 10);
        margin: 5px 5px;
        @include centeredRow;
        width: calc(50% - 10px);
        padding: 5px 8px;
        font-size: 14px;
        color: $white;

        .name {
          flex: 1;
          text-align: left;
        }

        i {
          width: 35px;
          color: $appPrimary;
          font-size: 20px;
          text-align: left;
        }
      }
    }
  }
}
