.Dashboard_wrapper {
  padding-top: 70px;

  .Dashboard_container {
    min-height: calc(100vh - 120px);
    padding: 15px 25px;
    transition-duration: 0.3s;
    &.sb_open {
      margin-left: 255px;
      width: calc(100% - 270px);
    }
    &.sb_close {
      margin-left: 85px;
      width: calc(100% - 100px);
    }

    .dashboardRow {
      @include centeredRow;
    }

    div {
      .dashPageTitle {
        background-color: mix(#000000, $bgPrimary, 10);
        color: mix(#ffffff, $bgPrimary, 40);
        font-weight: normal;
        width: 100%;
        text-align: left;
        margin-top: 0px;
        border-radius: 5px;
        padding-left: 15px;
        height: 35px;
        font-size: 25px;

        &::before {
          position: absolute;
          content: "";
          background-color: mix(#000000, $appPrimary, 20);
          width: 3px;
          height: 35px;
          border-radius: 2px;
          margin-left: -15px;
        }
      }
    }

    .chartBox {
      width: 100%;
      background-color: mix(#000000, $bgPrimary, 20);
      border-radius: 8px;
      padding: 8px 15px;
      margin: 4px;

      .title {
        font-weight: normal;
        margin: 0px;
        text-align: left;
        color: $appPrimary;
        font-size: 14px;
        margin-bottom: 8px;
        margin-top: 0px;
      }

      .content {
        height: calc(100% - 30px);

        &.centered {
          @include centeredBox;
        }
        .echarts-for-react {
          div {
            width: 100% !important;

            canvas {
              width: auto !important;
            }
          }
        }
      }
    }
  }
}
