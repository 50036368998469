.dashSettings_container{

  .backendURL{
    @include centeredRow;
    margin-top: 25px;
    margin-bottom: 25px;

    .input{
      margin-top: 0px;
      flex: 1;
    }
    .button{
      margin: 0px 15px;
    }
  }
}