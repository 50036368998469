.dashHeader_wrapper {
  position: fixed;
  top: 0px;
  height: 70px;
  background-color: mix(#000000, $bgPrimary, 20);

  box-shadow: 5px 0px 8px 2px #00000055;
  width: 100%;
  padding: 5px 15px;

  @include centeredRow;

  .logoBlock {
    @include centeredBox;

    img {
      height: 45px;
    }
  }

  .menuBlock{

    @include centeredRow;

    img{
      width: 35px;
      height: 35px;
      margin-right: 15px;
      border-radius: 50%;
      background-color: mix(#ffffff, $bgPrimary, 10);
    }

    i{
      font-size: 25px;
      color: $txtPrimary;
    }
  }
}
