// Main color
$white: #ffffff;
$black: #000000;
$blue: #1da1f2;
$green: #5caa02;
$red: #e43535;
$yellow: #d8bc1b;

$iLabs_blue : #007dc4;
$iLabs_yellow : #fbaf1a;

$appPrimary: #1da1f2;
// $appPrimary : #7158e2;
// $appPrimary : #1289A7;

// background colors
$bgPrimary: #26273d;
$bgPrimary_transparent: #26273d88;
$bgSecondary: #EEEEEE;
$bgSecondary_transparent: #30415188;
$chartBox: #192734;
$bgfooter: #35414a;
$userCard: #161616;

// text Colors
$txtPrimary: #ffffff;
$txtActive: #264653;
$txtSecondary: #D1D1D1;
$txtSuccess: #0acf0a;

// border colors
$borderPrimary: #d5d5d5;
$borderSecondary: #203849;
$homeMenuText: #304151;
$homeContentText: #35414a;

$shadowPrimary: #00000029;