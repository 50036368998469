@mixin centeredRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin centeredBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 100)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  label {
    color: $white;
    font-size: 18px;
  }
  input {
    @include formInput;
    &:focus {
      outline: none;
      // box-shadow: 0px 0px 3px 1px mix($appPrimary, $bgPrimary, 100%);
    }
  }

  button {
    @include btnPrimary;
  }
}

@mixin formInput {
  border: none;
  padding: 8px;
  border-radius: 8px;
  color: $appPrimary;
  background-color: $bgSecondary;
}

@mixin btnPrimary {
  background: $appPrimary;
  border: none;
  // border: 1px solid $borderPrimary;
  border-radius: 4px;
  padding: 7px 17px;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Segoe UI";
  letter-spacing: 0px;
  color: $white;
  transition-duration: 0.5s;
  &:hover {
    background-color: mix($black, $appPrimary, 10%);
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

@mixin btnSecondary {
  background: $bgSecondary;
  border: 1px solid $borderPrimary;
  border-radius: 4px;
  padding: 7px 17px;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Segoe UI";
  letter-spacing: 0px;
  color: $txtPrimary;
  transition-duration: 0.5s;
  &:hover {
    background-color: mix($black, $bgSecondary, 10%);
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

@mixin borderedBtnPrimary {
  background: transparent;
  border: solid 1px $appPrimary;
  border-radius: 4px;
  padding: 7px 17px;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Segoe UI";
  letter-spacing: 0px;
  color: $appPrimary;
  transition-duration: 0.5s;
  &:hover {
    background-color: mix($white, $appPrimary, 0%);
    color: $white;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

@mixin textButton {
  border: none;
  background-color: transparent;
  // border-bottom: 1px solid $borderPrimary;
  padding: 8px;
  color: $txtPrimary;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
}

@mixin iconButton {
  @include centeredBox;
  border: solid 1px $borderPrimary;
  background-color: transparent;
  // padding: 8px;
  border-radius: 6px;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
}

@mixin bluredButton {
  border: solid 1px $borderPrimary;
  background-color: transparent;
  backdrop-filter: blur(15px);
  padding: 7px 17px;
  border-radius: 6px;
  color: $white;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
}

@mixin randomFontColors {
  &:nth-child(1n) {
    color: #e53935;
  }
  &:nth-child(2n) {
    color: #fdd835;
  }
  &:nth-child(3n) {
    color: #7cb342;
  }
  &:nth-child(4n) {
    color: #f4511e;
  }
  &:nth-child(5n) {
    color: #d81b60;
  }
  &:nth-child(6n) {
    color: #039be5;
  }
  &:nth-child(7n) {
    color: #8e24aa;
  }
  &:nth-child(8n) {
    color: #fb8c00;
  }
  &:nth-child(9n) {
    color: #6d4c41;
  }
  &:nth-child(10n) {
    color: #1289a7;
  }
}
