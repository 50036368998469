.dashOverview_container {
  .topCardContainer {
    @include centeredRow;

    .card {
      flex: 1;
      background-color: mix(#000000, $bgPrimary, 20);
      border-radius: 8px;
      padding: 8px 15px;
      margin: 8px;
      @include randomFontColors;

      .title {
        font-weight: normal;
        margin: 0px;
        text-align: left;
        color: $appPrimary;
        font-size: 14px;
        margin-bottom: 8px;
      }
      .value {
        margin: 0px;
        font-weight: 100;
        font-size: 45px;
      }
    }
  }

  hr {
    border-color: $homeMenuText;
    border-style: solid;
  }

  .statusCard {
    
      height: 100%;
    .statusText {
      margin-bottom: 0px;
      margin-top: 0px;
      font-weight: 300;
      font-size: 40px;
      color: $green;

      &.warning {
        color: $yellow;
      }

      &.error {
        color: $red;
      }
    }
    .chipBox {
      @include centeredBox;
      flex-direction: row;
      flex-wrap: wrap;
      .chip {
        margin: 5px;
        color: $white;
        &.allow {
          background-color: transparent;
          border: solid 1px $green;
          color: $green;
        }
        &.Disallow {
          background-color: transparent;
          border: solid 1px $red;
          color: $red;
        }
        &.Sitemap {
          background-color: transparent;
          border: solid 1px $blue;
          color: $blue;
        }
      }
    }
  }
}
