@import "Variables";
@import "Mixins";
@import "fonts";
@import "alerts";

@import "Home";
@import "Dashboard";
@import "dashHeader";
@import "dashSidebar";
@import "Dash_Overview";
@import "Dash_Users";
@import "Dash_Settings";
@import "dash_LineChart";
@import "productSelector";
@import "loadingScreen";

* {
  box-sizing: border-box;
  font-family: Roboto;
}

body {
  font-family: "Segoe UI";
  background-color: $bgPrimary;
}

input:focus,
input:focus, button:focus {
  outline: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.MuiGrid-item{
  transition-duration: 0.5s;
}