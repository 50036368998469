.adminSidebar_wrapper {
  position: fixed;
  top: 70px;
  height: calc(100vh - 70px);
  background-color: mix(#000000, $bgPrimary, 20);
  // min-height: 100vh;
  transition: width 0.3s;
  box-shadow: 5px 0px 8px 2px #00000055;
  box-sizing: border-box;
  padding: 15px 0px;

  @include centeredRow;
  flex-direction: column;

  // background-image: url("/Images/dashboardLanding.png");
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  &.open {
    width: 240px;
  }
  &.close {
    width: 70px;
  }

  .Header {
    width: 100%;
    @include centeredRow;
    margin-bottom: 15px;
    img {
      height: 30px;
      padding: 8px 20px;
      box-sizing: content-box;
    }
    .toggleIcon {
      padding: 8px 25px;
      cursor: pointer;
      font-size: 20px;
      color: mix(#ffffff, $bgPrimary, 40);
      transition-duration: 0.5;
      width: 100%;
      text-align: right;

      &:hover {
        color: $white;
      }
    }
  }
  .nav {
    flex: 1;
    width: 100%;
    overflow: hidden;
    @include centeredRow;
    flex-direction: column;

    .linkGroup {
      width: 100%;

      a,
      button {
        background-color: transparent;
        border: none;
        @include centeredRow;
        justify-content: flex-start;
        width: 240px;
        overflow: hidden;
        font-size: 16px;
        padding: 15px 20px;
        margin-bottom: 8px;
        text-decoration: none;

        color: mix(#ffffff, $bgPrimary, 40);
        transition-duration: 0.5;

        &:hover {
          color: $white;
        }

        &.active {
          color: $blue;
          border-left: solid 2px $blue;
          background-color: #00000011;
        }

        i {
          width: 30px;
          font-size: 20px;
          margin-right: 20px;
          text-align: center;
        }
      }
    }
  }
}
